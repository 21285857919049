import React from "react";

const Terms = () => {
  return (
    <div className="center">
      <div className="container">
        <h2>Disclaimer</h2>
        <p>
          Dr. CBD hereby grants you access to https://drcbdstore.com (“the
          Website”) and invites you to purchase the services offered here.
        </p>

        <h3>Definitions and key terms</h3>
        <p>
          To help explain things as clearly as possible in this Disclaimer,
          every time any of these terms are referenced, are strictly defined as:
        </p>

        <p>
          -Cookie: small amount of data generated by a website and saved by your
          web browser. It is used to identify your browser, provide analytics,
          remember information about you such as your language preference or
          login information.
        </p>

        <p>
          -Company: when this policy mentions “Company,” “we,” “us,” or “our,”
          it refers to Dr. CBD Co., Ltd, (255 Bangna Trat 3, Bangna, Bangkok
          10260 Thailand) that is responsible for your information under this
          Disclaimer.
        </p>

        <p>
          -Service: refers to the service provided by Dr. CBD as described in
          the relative terms (if available) and on this platform.
        </p>

        <p>
          -Website: Dr. CBD.”’s” site, which can be accessed via this URL:
          https://drcbdstore.com
        </p>

        <p>
          -You: a person or entity that is registered with Dr. CBD to use the
          Services.
        </p>

        <h3>Limited liability</h3>
        <p>
          Dr. CBD endeavors to update and/or supplement the content of the
          website on a regular basis. Despite our care and attention, content
          may be incomplete and/or incorrect.
        </p>

        <p>
          The materials offered on the website are offered without any form of
          guarantee or claim to their correctness. These materials can be
          changed at any time without prior notice from Dr. CBD.
        </p>

        <p>
          Particularly, all prices on the website are stated subject to typing
          and programming errors. No liability is assumed for the implications
          of such errors. No agreement is concluded on the basis of such errors.
        </p>

        <p>
          Dr. CBD shall not bear any liability for hyperlinks to websites or
          services of third parties included on the website. From our website,
          you can visit other websites by following hyperlinks to such external
          sites. While we strive to provide only quality links to useful and
          ethical websites, we have no control over the content and nature of
          these sites. These links to other websites do not imply a
          recommendation for all the content found on these sites. Site owners
          and content may change without notice and may occur before we have the
          opportunity to remove a link which may have gone ‘bad’.
        </p>

        <p>
          Please be also aware that when you leave our website, other sites may
          have different privacy policies and terms which are beyond our
          control. Please be sure to check the Privacy Policies of these sites
          as well as their “Terms of Service” before engaging in any business or
          uploading any information.
        </p>

        <h3>Links to Other Websites Disclaimer</h3>
        <p>
          This Disclaimer applies only to the Services. The Services may contain
          links to other websites not operated or controlled by Dr. CBD. We are
          not responsible for the content, accuracy or opinions expressed in
          such websites, and such websites are not investigated, monitored, or
          checked for accuracy or completeness by us. Please remember that when
          you use a link to go from the Services to another website, our Privacy
          Policy is no longer in effect. You’re browsing and interaction on any
          other website, including those that have a link on our platform, is
          subject to that website’s own rules and policies. Such third parties
          may use their own cookies or other methods to collect information
          about you. If You click on a third-party link, you will be directed to
          that third party’s site. We strongly advise You to review the Privacy
          Policy and Terms of every site You visit.
        </p>

        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third-party sites or services.
        </p>

        <h3>Errors and Omissions Disclaimer</h3>
        <p>
          Dr. CBD is not responsible for any content, code, or any other
          imprecision.
        </p>

        <p>Dr. CBD does not provide warranties or guarantees.</p>

        <p>
          In no event shall Dr. CBD be liable for any special, direct, indirect,
          consequential, or incidental damages or any damages whatsoever,
          whether in an action of contract, negligence or other tort, arising
          out of or in connection with the use of the Service or the contents of
          the Service. Dr. CBD reserves the right to make additions, deletions,
          or modifications to the contents on the Service at any time without
          prior notice.
        </p>

        <h3>General Disclaimer</h3>
        <p>
          The Dr. CBD Service and its contents are provided “as is” and “as
          available” without any warranty or representations of any kind,
          whether express or implied. Dr. CBD is a distributor and not a
          publisher of the content supplied by third parties; as such, Dr. CBD
          exercises no editorial control over such content and makes no warranty
          or representation as to the accuracy, reliability or currency of any
          information, content, service or merchandise provided through or
          accessible via the Dr. CBD Service. Without limiting the foregoing,
          Dr. CBD specifically disclaims all warranties and representations in
          any content transmitted on or in connection with the Dr. CBD Service
          or on sites that may appear as links on the Dr. CBD Service, or in the
          products provided as a part of, or otherwise in connection with, the
          Dr. CBD Service, including without limitation any warranties of
          merchantability, fitness for a particular purpose or non-infringement
          of third party rights. No oral advice or written information given by
          Dr. CBD or any of its affiliates, employees, officers, directors,
          agents, or the like will create a warranty. Price and availability
          information is subject to change without notice. Without limiting the
          foregoing, Dr. CBD does not warrant that the Dr. CBD Service will be
          uninterrupted, uncorrupted, timely, or error-free.
        </p>

        <h3>Copyright Disclaimer</h3>
        <p>
          All intellectual property rights concerning these materials are vested
          in Dr. CBD. Copying, distribution and any other use of these materials
          is not permitted without the written permission of Dr. CBD, except and
          only to the extent otherwise provided in regulations of mandatory law
          (such as the right to quote), unless otherwise stated for certain
          materials.
        </p>

        <h3>Affiliate Links Disclosure</h3>
        <p>
          Dr. CBD has affiliate links and in this section of the Disclaimer we
          will address how we use those affiliate links from other
          websites/companies and products. These “affiliate links” are specific
          URLs that contain the affiliate’s ID or username.
        </p>

        <h4>
          In compliance with the FTC guidelines, please assume the following
          about links and posts on this site:
        </h4>

        <p>
          -Any/all of the links on Dr. CBD are affiliate links of which we
          receive a small commission from sales of certain items, but the price
          is the same for you. As Dr. CBD has grown, so have costs associated
          with running and maintaining it, and affiliate links are a way we help
          offset these costs.
        </p>

        <p>
          -If we post an affiliate link to a product, it is something that we
          personally use, support and would recommend without an affiliate link.
        </p>

        <p>
          -Unless otherwise noted, all reviews are of items we have purchased,
          and we are not paid or compensated in any way.
        </p>

        <h3>Health Disclosure</h3>
        <p>
          The information on this website such as text, graphics, images, and
          other materials created by Dr. CBD or obtained from Dr. CBD.”’s”
          licensors, and other materials contained on Dr. CBD (collectively,
          “content”) is intended solely for informational purposes and may not
          be used as a substitute for professional advice and/or information, as
          circumstances will vary from person to person. You should not act or
          rely upon this information without seeking professional advice. Do not
          attempt any of the suggested actions, solutions, remedies, or
          instructions found on this website without first consulting with a
          qualified professional. The materials are not intended to be, nor do
          they constitute actionable professional advice. Transmissions of this
          information is not intended to create a professional-client
          relationship between Dr. CBD and you. The owners, editors,
          contributors, administrators, and other staff of Dr. CBD are not
          qualifying professionals and are simply aggregating information found
          online for informational purposes only.
        </p>

        <p>
          If you think you may have a medical emergency, call your doctor or 199
          immediately. Dr. CBD does not recommend or endorse any specific tests,
          physicians, products, procedures, opinions, or other information that
          may be mentioned on the website. Reliance on any information provided
          by Dr. CBD, Dr. CBD employees, others appearing on the website at the
          invitation of Dr. CBD, or other visitors to the website is solely at
          your own risk.
        </p>

        <h3>Advertising Disclosure</h3>
        <p>
          This website may contain third party advertisements and links to third
          party sites. Dr. CBD does not make any representation as to the
          accuracy or suitability of any of the information contained in those
          advertisements or sites and does not accept any responsibility or
          liability for the conduct or content of those advertisements and sites
          and the offerings made by the third parties.
        </p>

        <p>
          Advertising keeps Dr. CBD and many of the websites and services you
          use free of charge. We work hard to make sure that ads are safe,
          unobtrusive, and as relevant as possible.
        </p>

        <p>
          Third party advertisements and links to other sites where goods or
          services are advertised are not endorsements or recommendations by Dr.
          CBD of the third-party sites, goods, or services. Dr. CBD takes no
          responsibility for the content of any of the ads, promises made, or
          the quality/reliability of the products or services offered in all
          advertisements.
        </p>

        <h3>Testimonials Disclosure</h3>
        <p>
          Any testimonials provided on this platform are opinions of those
          providing them. The information provided in the testimonials is not to
          be relied upon to predict results in your specific situation. The
          results you experience will be dependent on many factors including but
          not limited to your level of personal responsibility, commitment, and
          abilities, in addition to those factors that you and/or Dr. CBD may
          not be able to anticipate.
        </p>

        <p>
          We will give honest testimonials to our visitors regardless of any
          discount. Any product or service that we test are individual
          experiences, reflecting real life experiences. The testimonials could
          be displayed on audio, text or video and are not necessarily
          representative of all of those who will use our products and/or
          services.
        </p>

        <p>
          Dr. CBD does not guarantee the same results as the testimonials given
          on our platform. Testimonials presented on Dr. CBD are applicable to
          the individuals writing them and may not be indicative of future
          success of any other individuals. Please don’t hesitate to contact us
          if you would like to know more about testimonials, discounts, or any
          of the products/services that we review.
        </p>

        <h3>Your Consent</h3>
        <p>
          We’ve updated our Disclaimer to provide you with complete transparency
          into what is being set when you visit our site and how it’s being
          used. By using our website, registering an account, or making a
          purchase, you hereby consent to our Disclaimer and agree to its terms.
        </p>

        <h3>Changes To Our Disclaimer</h3>
        <p>
          Should we update, amend or make any changes to this document so that
          they accurately reflect our Service and policies. Unless otherwise
          required by law, those changes will be prominently posted here. Then,
          if you continue to use the Service, you will be bound by the updated
          Disclaimer. If you do not want to agree to this or any updated
          Disclaimer, you can delete your account.
        </p>

        <h3>Contact Us</h3>
        <p>
          Don’t hesitate to contact us if you have any questions regarding this
          Disclaimer.
        </p>
        <p>-Via Email: info@drcbdgroup.com</p>
        <p>-Via Phone Number: +6651416999</p>
        <p>-Via this Link: https://drcbdstore.com</p>
        <p>-Via this Address: 255 Bangna-Trat 3</p>
        <h3>Business Policy for the Sale of CBD-Infused Products</h3>
        <h4>Scope</h4>
        <p>
          This business policy is a document that outlines guidelines and
          standards for our business focusing on the sale of products that
          contain CBD (Cannabidiol). Please note that this policy serves as a
          general operational document and does not constitute any legal
          contract or agreement with our customers or partnering companies.
        </p>

        <h4>Legal Compliance</h4>
        <p>
          Our company conducts regular reviews and ensures compliance with all
          relevant laws and regulations concerning the sale of CBD-infused
          products. This includes laws regarding production, importation,
          exportation, distribution, and usage of CBD, as per local and
          international requirements.
        </p>

        <h4>Quality Assurance Responsibility</h4>
        <p>
          We are committed to providing the highest quality of our CBD-infused
          products. We only use CBD extracts that meet standardized and
          high-quality criteria. Our manufacturing and testing processes are
          rigorous to ensure the safety and effectiveness of our products, as
          per the prescribed standards.
        </p>

        <h4>Usage Requirements</h4>
        <p>
          We will only sell CBD-infused products that comply with the law. These
          products may have limitations in certain areas, and their sale or
          usage may be subject to local regulations. Customers should be aware
          of and comply with the laws and regulations that are enforceable in
          their respective jurisdictions when using these products.
        </p>

        <h4>Information and Recommendations</h4>
        <p>
          Our website may provide information regarding usage, benefits,
          precautions, or other recommendations related to CBD-infused products.
          Additionally, we may share research, experiments, or studies related
          to CBD extracts to provide accurate and informed information to our
          customers.
        </p>

        <h4>Compliance with Policies</h4>
        <p>
          We strictly adhere to the policies and regulations associated with the
          sale of CBD-infused products. We encourage all customers to comply
          with these requirements when using our products. However, we disclaim
          any liability for the consequences that may arise from the use of
          CBD-infused products purchased from unauthorized or non-compliant
          sources.
        </p>

        <h4>Policy Updates</h4>
        <p>
          We may periodically update this policy to align with any changes in
          laws and regulations pertaining to the sale of CBD-infused products.
          Accessing our website after policy updates will be considered an
          acceptance of the revised policy and adherence to its terms.
        </p>

        <p>
          Note: This business policy specifically addresses the sale of
          CBD-infused products. For content related to privacy policies,
          cookies, and other personal data, please refer to our privacy policy
          on our website.
        </p>
        <h3>Shipping Policy</h3>
        <p>
          Our objective is to provide our customers with the fastest and most
          efficient delivery service possible. Therefore, we have a shipping
          policy with the following details:
        </p>

        <h4>Notification of Delivery Details and Process:</h4>

        <p>
          When customers place an order for products through our website or
          online platform, we will send an email or message to inform them of
          the delivery details. This includes the order number, product list,
          shipping address, and chosen delivery method. This allows customers to
          review and verify the information before the shipment.
        </p>

        <h4>Estimated Delivery Time:</h4>

        <p>
          We aim to deliver the products to customers as quickly as possible.
          The actual delivery time depends on the shipping location and the
          chosen delivery method. Generally, we strive to deliver the products
          within 3-5 business days after order confirmation. However, in case of
          any exceptional circumstances that may cause delays, such as adverse
          weather conditions or transportation issues, we will make efforts to
          notify customers and keep them informed about the situation.
        </p>

        <h4>Procedure for Undelivered or Delayed Deliveries:</h4>

        <p>
          If customers do not receive their products within the specified
          timeframe or experience delays in delivery, please follow the
          following steps:
        </p>
        <p>
          a. Check Delivery Status: Customers can check the delivery status of
          their products through our website or online platform using the order
          number or tracking number provided in the email or delivery
          notification.
        </p>
        <p>
          b. Contact Customer Service: If customers are unable to check the
          delivery status or have additional questions, please contact our
          customer service department through the designated channels on our
          website or online platform. We are available to assist and provide
          further information.
        </p>
        <p>
          c. Problem Resolution: If there are issues resulting in non-delivery
          or delayed delivery, we will take prompt and appropriate actions
          according to our product return or compensation policy, as stated.
          Customers can reach out to our customer service department for
          assistance in resolving such issues.
        </p>

        <p>
          We strive to deliver high-quality and satisfactory shipping services
          to all our customers. If you have any further questions or require
          assistance regarding the shipping of your products, please do not
          hesitate to contact us through the provided channels. Thank you.
        </p>
        <h3>Cancellation Policy</h3>
        <p>
          We have a cancellation policy in place to allow customers to cancel
          their orders. The conditions for cancellation are as follows:
        </p>

        <p>
          1. Cancellation Conditions: Customers can cancel their orders before
          the products are shipped or the processing of the shipment has begun.
          Once the products have been shipped, we cannot cancel the order.
          Please check the delivery status of your products before initiating a
          cancellation to ensure accuracy and prevent any inconvenience in the
          process.
        </p>

        <p>
          2. Cancellation Method: Customers can cancel their orders by
          contacting our customer service team through the channels specified on
          our website or application. Please provide accurate and complete
          information regarding the order you wish to cancel for swift
          processing.
        </p>

        <p>
          3. Refund Policy: Upon successful cancellation of an order according
          to the specified conditions, we will proceed with the refund using the
          original payment method used for the purchase. Please note that the
          time taken for the refund to reflect may vary depending on the payment
          method and the terms of the financial service provider. Click to read
          full details
        </p>

        <p>
          If there are no specific conditions mentioned for canceling an order
          as stated above, it means that we reserve the right to cancel the
          order in all cases. There may be additional terms and conditions
          specified in our service terms and conditions.
        </p>

        <p>
          Please note that this cancellation policy may be subject to changes or
          updates over time. Therefore, we kindly request customers to review
          this policy periodically to stay informed about the most up-to-date
          information.
        </p>
        <h3>Refund and Returns Policy</h3>

        <h4>Refund Policy</h4>

        <p>
          We have a 3-days return policy, which means you have 3 days after
          receiving your item to request a return.
        </p>

        <p>
          To be eligible for a return, your item must be in the same condition
          that you received it, unworn or unused, with tags, and in its original
          packaging. You’ll also need the receipt or proof of purchase.
        </p>

        <p>
          To start a return, you can contact us at info@drcbdgroup.com. Please
          note that returns will need to be sent to the following address:
          info@drcbdgroup.com
        </p>

        <p>
          If your return is accepted, we’ll send you a return shipping label, as
          well as instructions on how and where to send your package. Items sent
          back to us without first requesting a return will not be accepted.
        </p>

        <p>
          You can always contact us for any return question at
          info@drcbdgroup.com.
        </p>

        <h4>Damages and issues</h4>
        <p>
          Please inspect your order upon reception and contact us immediately if
          the item is defective, damaged or if you receive the wrong item, so
          that we can evaluate the issue and make it right.
        </p>

        <h4>Exceptions / non-returnable items</h4>
        <p>
          Certain types of items cannot be returned, like perishable goods (such
          as food, flowers, or plants), custom products (such as special orders
          or personalized items), and personal care goods (such as beauty
          products). We also do not accept returns for hazardous materials,
          flammable liquids, or gases. Please get in touch if you have questions
          or concerns about your specific item.
        </p>

        <p>
          Unfortunately, we cannot accept returns on sale items or gift cards.
        </p>

        <h4>Exchanges</h4>
        <p>
          The fastest way to ensure you get what you want is to return the item
          you have, and once the return is accepted, make a separate purchase
          for the new item.
        </p>

        <h4>European Union 14 day cooling off period</h4>
        <p>
          Notwithstanding the above, if the merchandise is being shipped into
          the European Union, you have the right to cancel or return your order
          within 14 days, for any reason and without a justification. As above,
          your item must be in the same condition that you received it, unworn
          or unused, with tags, and in its original packaging. You’ll also need
          the receipt or proof of purchase.
        </p>

        <h4>Refunds</h4>
        <p>
          We will notify you once we’ve received and inspected your return, and
          let you know if the refund was approved or not. If approved, you’ll be
          automatically refunded on your original payment method within 10
          business days. Please remember it can take some time for your bank or
          credit card company to process and post the refund too.
        </p>

        <p>
          If more than 15 business days have passed since we’ve approved your
          return, please contact us at info@drcbdgroup.com
        </p>

        <p>
          To be eligible for a return, your item must be unused and in the same
          condition that you received it. It must also be in the original
          packaging.
        </p>

        <p>
          Several types of goods are exempt from being returned. Perishable
          goods such as food, flowers, newspapers or magazines cannot be
          returned. We also do not accept products that are intimate or sanitary
          goods, hazardous materials, or flammable liquids or gases.
        </p>

        <h4>Additional non-returnable items:</h4>

        <h4>Gift cards</h4>
        <p>Downloadable software products</p>
        <p>Some health and personal care items</p>
        <p>
          To complete your return, we require a receipt or proof of purchase.
        </p>

        <p>Please do not send your purchase back to the manufacturer.</p>

        <p>
          There are certain situations where only partial refunds are granted:
        </p>

        <p>Book with obvious signs of use</p>
        <p>
          CD, DVD, VHS tape, software, video game, cassette tape, or vinyl
          record that has been opened. Any item not in its original condition,
          is damaged or missing parts for reasons not due to our error.
        </p>
        <p>Any item that is returned more than 30 days after delivery</p>
        <h4>Late or missing refunds</h4>
        <p>
          If you haven’t received a refund yet, first check your bank account
          again.
        </p>

        <p>
          Then contact your credit card company, it may take some time before
          your refund is officially posted.
        </p>

        <p>
          Next contact your bank. There is often some processing time before a
          refund is posted.
        </p>

        <p>
          If you’ve done all of this and you still have not received your refund
          yet, please contact us at {`{email address}`}.
        </p>

        <h5>Sale items</h5>

        <p>
          Only regular priced items may be refunded. Sale items cannot be
          refunded.
        </p>

        <h4>Exchanges</h4>
        <p>
          We only replace items if they are defective or damaged. If you need to
          exchange it for the same item, send us an email at {`{email address}`}{" "}
          and send your item to: {`{physical address}`}.
        </p>

        <h4>Gifts</h4>
        <p>
          If the item was marked as a gift when purchased and shipped directly
          to you, you’ll receive a gift credit for the value of your return.
          Once the returned item is received, a gift certificate will be mailed
          to you.
        </p>

        <p>
          If the item wasn’t marked as a gift when purchased, or the gift giver
          had the order shipped to themselves to give to you later, we will send
          a refund to the gift giver and they will find out about your return.
        </p>

        <h4>Shipping returns</h4>
        <p>
          To return your product, you should mail your product to:
          {`{physical address}`}.
        </p>

        <p>
          You will be responsible for paying for your own shipping costs for
          returning your item. Shipping costs are non-refundable. If you receive
          a refund, the cost of return shipping will be deducted from your
          refund.
        </p>

        <p>
          Depending on where you live, the time it may take for your exchanged
          product to reach you may vary.
        </p>

        <p>
          If you are returning more expensive items, you may consider using a
          trackable shipping service or purchasing shipping insurance. We don’t
          guarantee that we will receive your returned item.
        </p>

        <h4>Need help?</h4>
        <p>
          Contact us at info@drcbdgroup.com for questions related to refunds and
          returns.
        </p>
      </div>
    </div>
  );
};

export default Terms;
